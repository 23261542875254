import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Mark from '../components/mark'
import Card from '../components/card'
import Card1 from '../components/card1'
import Card11 from '../components/card11'
import Review from '../components/review'
import Review1 from '../components/review1'
import Review11111 from '../components/review11111'
import Review11 from '../components/review11'
import Review1111 from '../components/review1111'
import Review111 from '../components/review111'
import Contact1 from '../components/contact1'
import Contact11 from '../components/contact11'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>REC-IT Services</title>
        <meta property="og:title" content="REC-IT Services" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <section id="1" className="home-section1">
        <div className="home-hero">
          <div className="home-content">
            <main className="home-main">
              <header className="home-header">
                <h1 className="home-heading">
                  Votre Partenaire en Consultation IT et Boosting Technologique
                </h1>
                <span className="home-caption">
                  Accédez à des services de consulting IT spécialisés pour la
                  migration, l&apos;intégration de l&apos;infrastructure, la
                  sauvegarde/DR, et l&apos;intégration DevOps.
                </span>
              </header>
              <div className="home-buttons">
                <a
                  href="https://tidycal.com/ai-rec/15-minute-meeting"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <div className="home-get-started button">
                    <span className="home-text">Réserver une consultation</span>
                  </div>
                </a>
                <div className="home-get-started1 button">
                  <span className="home-text01">Consulter nos services</span>
                </div>
              </div>
            </main>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="/SectionImages/images-200h.png"
                  className="home-image avatar"
                />
                <img
                  alt="image"
                  src="/SectionImages/images-200h.jpg"
                  className="home-image1 avatar"
                />
                <img
                  alt="image"
                  src="/SectionImages/capture-200w.png"
                  className="home-image2 avatar"
                />
              </div>
              <label className="home-caption1">
                Plus de 10 certifications et 20 projets IT, incluant des grands
                comptes.
              </label>
            </div>
          </div>
          <div className="home-image3">
            <img
              alt="image"
              src="/SectionImages/untitled%20design%20(5)-1500h.png"
              className="home-image4"
            />
          </div>
          <div className="home-image5">
            <img
              alt="image"
              src="/SectionImages/untitled%20design%20(5)-1500h.png"
              className="home-image6"
            />
          </div>
        </div>
      </section>
      <section id="2" className="home-section2">
        <header className="home-header1">
          <h2 className="home-text02">
            Consulting IT Senior : Expertise à la Demande
          </h2>
          <span className="home-text03">
            Expertise en Intégration de Datacenter, Migration, Sauvegarde/DR et
            Intégration DevOps
          </span>
        </header>
        <section className="home-note">
          <div className="home-image7">
            <img
              alt="pastedImage"
              src="/external/pastedimage-5q75-600w.png"
              className="home-pasted-image"
            />
          </div>
          <div className="home-content1">
            <div className="home-main1">
              <div className="home-heading1">
                <h2 className="section-heading">
                  Services de consultation IT couvrent le spectre de votre
                  écosystème IT
                </h2>
                <p className="section-description">
                  <span>
                    Expertise en infrastructure IT : serveurs, stockage, réseaux
                    SAN (Dell , HPE, Nutanix, VxRail)
                  </span>
                  <br></br>
                  <br></br>
                  <span>Administration et migration vers le cloud Azure.</span>
                  <br></br>
                  <br></br>
                  <span>
                    Spécialisation en virtualisation : VMware, Hyper-V.
                  </span>
                  <br></br>
                  <br></br>
                  <span>Compétences en systèmes : Active Directory, M365.</span>
                  <br></br>
                  <br></br>
                  <span>
                    Innovation en modernisation des applications : Kubernetes,
                    Docker, Jenkins, GitLab.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Support complet pour toutes les couches de votre écosystème
                    IT.
                  </span>
                </p>
              </div>
            </div>
            <div className="home-get-started2 button">
              <span className="home-text20">Contactez-nous</span>
            </div>
          </div>
        </section>
        <section className="home-note1">
          <div className="home-image8">
            <img
              alt="pastedImage"
              src="/external/pastedimage-b9d-600h.png"
              className="home-pasted-image1"
            />
          </div>
          <div className="home-content2">
            <main className="home-main2">
              <main className="home-heading3">
                <header className="home-header2">
                  <h2 className="section-heading">
                    Consultation de Roadmap de Datacenter
                  </h2>
                  <p className="section-description">
                    Notre service de consultation de roadmap de datacenter vous
                    guide à travers une transformation IT réussie.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </header>
                <div className="home-checkmarks">
                  <Mark></Mark>
                  <div className="home-mark">
                    <div className="home-icon">
                      <svg viewBox="0 0 1024 1024" className="home-icon1">
                        <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                      </svg>
                    </div>
                    <p className="home-label">
                      Analyse des Données et Recommandations sur mesure.
                    </p>
                  </div>
                  <div className="home-mark1">
                    <div className="home-icon3">
                      <svg viewBox="0 0 1024 1024" className="home-icon4">
                        <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                      </svg>
                    </div>
                    <p className="home-label1">
                      Développement de la Roadmap Incluant de plans,
                      technologies et budgets.
                    </p>
                  </div>
                </div>
              </main>
            </main>
            <div className="home-get-started3 button">
              <span className="home-text21">Contactez-nous</span>
            </div>
          </div>
        </section>
      </section>
      <section className="home-section">
        <header className="home-header3">
          <header className="home-left">
            <h2 className="section-heading">
              Notre Expertise et Nos Partenariats en IT
            </h2>
          </header>
          <div className="home-right">
            <p className="home-paragraph2 section-description">
              Technologies Innovantes, Certifications Reconnaissables, et
              Projets Réussis pour Grands Comptes
            </p>
          </div>
        </header>
        <main className="home-cards">
          <Card rootClassName="card-root-class-name"></Card>
          <Card1 rootClassName="card1-root-class-name3"></Card1>
          <Card11 rootClassName="card11-root-class-name3"></Card11>
        </main>
      </section>
      <section id="3" className="home-section3">
        <header className="home-header4">
          <header className="home-left1">
            <h2 className="home-heading6 section-heading">
              Témoignages de Nos Clients et Partenaires
            </h2>
          </header>
          <div className="home-right1">
            <p className="home-paragraph3 section-description">
              Découvrez les Avis de Ceux qui Nous Font Confiance
            </p>
          </div>
        </header>
        <main className="home-cards1">
          <div className="home-container1">
            <Review rootClassName="review-root-class-name"></Review>
            <Review1 rootClassName="review1-root-class-name6"></Review1>
          </div>
          <div className="home-container2">
            <Review11111 rootClassName="review11111-root-class-name6"></Review11111>
            <Review11 rootClassName="review11-root-class-name6"></Review11>
          </div>
          <div className="home-container3">
            <Review1111 rootClassName="review1111-root-class-name6"></Review1111>
            <Review111 rootClassName="review111-root-class-name6"></Review111>
          </div>
        </main>
        <div className="home-view-more">
          <p className="home-text22">View more</p>
        </div>
      </section>
      <section id="4" className="home-section4">
        <main className="home-content3">
          <header className="home-header5">
            <h2 className="home-heading7 section-heading">Contactez-nous</h2>
            <Contact1 rootClassName="contact1-root-class-name21"></Contact1>
            <Contact11 rootClassName="contact11-root-class-name21"></Contact11>
            <p className="home-paragraph4 section-description">
              <span className="home-text23">
                Rencontrez la Fondatrice de REC
              </span>
              <br></br>
              <br></br>
              <span className="home-text26">
                Yasmine BOUDHINA est une consultante IT experte dirige REC avec
                un engagement fort envers l&apos;innovation et l&apos;excellence
                technique.
              </span>
              <br></br>
            </p>
          </header>
          <img
            alt="pastedImage"
            src="/external/pastedimage-zegh-1200w.png"
            className="home-pasted-image2"
          />
        </main>
      </section>
      <footer className="home-footer">
        <div className="home-content4">
          <main className="home-main-content">
            <div className="home-content5">
              <header className="home-main3">
                <div className="home-header6">
                  <img
                    alt="image"
                    src="/external/untitled%20design%20(1).svg"
                    className="home-branding"
                  />
                  <span className="home-text28">
                    Votre Partenaire en Consultation IT et Boosting
                    Technologique
                  </span>
                </div>
                <div className="home-socials">
                  <a
                    href="https://www.linkedin.com/in/yasmine-rec/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link1"
                  >
                    <img
                      alt="image"
                      src="/Icons/linkedin-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://twitter.com/RecTunisia45619"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    <img
                      alt="image"
                      src="/Icons/twitter-200h.png"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <header className="home-categories">
                <div className="home-category">
                  <div className="home-header7">
                    <span className="footer-header">Services</span>
                  </div>
                  <div className="home-links">
                    <span className="footer-link">
                      Consulting IT Senior : Expertise à la Demande
                    </span>
                    <span className="footer-link">
                      Consultation de Roadmap de Datacenter
                    </span>
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright">
              <span className="home-text32">
                © 2024 REC. All Rights Reserved.
              </span>
            </section>
          </main>
          <main className="home-subscribe">
            <main className="home-main4">
              <h1 className="home-heading8">
                Abonnez-vous à notre chaîne YouTube
              </h1>
              <div className="home-input-field">
                <a
                  href="https://www.youtube.com/@rec-yas?sub_confirmation=1"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link3"
                >
                  <div className="home-buy button">
                    <span className="home-text33">-&gt;</span>
                    <span className="home-text34">
                      <span>Subscribe now</span>
                      <br></br>
                    </span>
                  </div>
                </a>
              </div>
            </main>
            <h1 className="home-notice">
              Découvrez nos dernières vidéos, tutoriels, et webinaires sur
              l&apos;IT et la transformation digitale.
            </h1>
          </main>
          <section className="home-copyright1">
            <span className="home-text37">
              © 2022 latitude. All Rights Reserved.
            </span>
          </section>
        </div>
      </footer>
      <div>
        <div className="home-container5">
          <Script
            html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
the Accordions will still work*/

const listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };


const runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
