import React from 'react'

import PropTypes from 'prop-types'

import './review11.css'

const Review11 = (props) => {
  return (
    <section className={`review11-card ${props.rootClassName} `}>
      <div className="review11-stars">
        <svg viewBox="0 0 1024 1024" className="review11-icon">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11-icon02">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11-icon04">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11-icon06">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11-icon08">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
      </div>
      <main className="review11-content">
        <p className="review11-quote">{props.quote}</p>
        <div className="review11-author">
          <div className="review11-details">
            <h1 className="review11-author1">{props.author}</h1>
            <label className="review11-position">{props.position}</label>
          </div>
        </div>
      </main>
    </section>
  )
}

Review11.defaultProps = {
  rootClassName: '',
  author: 'SKANDER ZOUCH',
  position: 'Directeur général chez Alios Finance Gabon',
  avatarAlt: 'image',
  quote:
    "Nous avons collaboré avec Yasmine Boudhina sur notre projet stratégique au sein d'anciennes entreprises intégratrices, qui englobait l'étude et la conception des infrastructures cibles pour l'ensemble de nos filiales réparties en Afrique. Sa maîtrise des solutions de divers constructeurs, associée à ses compétences de présentation et de conseil, ont joué un rôle essentiel dans la finalisation réussie du projet. La capacité de Yasmine à apporter une expertise technique précise et à la combiner avec des compétences de communication a grandement contribué à l'atteinte de nos objectifs. Nous sommes reconnaissants pour son professionnalisme, son engagement et sa contribution significative à la réussite de ce projet stratégique.",
  avatarSrc:
    'https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3NzU5NDE3&ixlib=rb-4.0.3&w=200',
}

Review11.propTypes = {
  rootClassName: PropTypes.string,
  author: PropTypes.string,
  position: PropTypes.string,
  avatarAlt: PropTypes.string,
  quote: PropTypes.string,
  avatarSrc: PropTypes.string,
}

export default Review11
