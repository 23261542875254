import React from 'react'

import PropTypes from 'prop-types'

import './review11111.css'

const Review11111 = (props) => {
  return (
    <section className={`review11111-card ${props.rootClassName} `}>
      <div className="review11111-stars">
        <svg viewBox="0 0 1024 1024" className="review11111-icon">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11111-icon02">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11111-icon04">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11111-icon06">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review11111-icon08">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
      </div>
      <main className="review11111-content">
        <p className="review11111-quote">{props.quote}</p>
        <div className="review11111-author">
          <div className="review11111-details">
            <h1 className="review11111-author1">{props.author}</h1>
            <label className="review11111-position">{props.position}</label>
          </div>
        </div>
      </main>
    </section>
  )
}

Review11111.defaultProps = {
  quote:
    "Yasmine Boudhina a été un atout essentiel au sein de notre équipe en tant que Consultante IT Sénior. Elle a joué un rôle déterminant dans le succès de plusieurs projets, contribuant de la phase avant-vente jusqu'à l'implémentation du datacenter, en passant par l'infrastructure, la virtualisation, et la sauvegarde, avec une expertise particulière sur les solutions des constructeurs HPE, Huawei, Veeam et VMware.Sa capacité à naviguer à la fois dans la complexité de la phase avant-vente et dans la mise en œuvre pratique de projets variés témoigne de son professionnalisme exceptionnel.",
  avatarAlt: 'image',
  rootClassName: '',
  author: 'Elyes Ben Othmen',
  avatarSrc:
    'https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3NzU5NDE3&ixlib=rb-4.0.3&w=200',
  position: 'directeur général adjoint chez Prologic',
}

Review11111.propTypes = {
  quote: PropTypes.string,
  avatarAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  author: PropTypes.string,
  avatarSrc: PropTypes.string,
  position: PropTypes.string,
}

export default Review11111
