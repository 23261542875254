import React from 'react'

import PropTypes from 'prop-types'

import './contact1.css'

const Contact1 = (props) => {
  return (
    <div className={`contact1-mark ${props.rootClassName} `}>
      <div className="contact1-icon">
        <svg viewBox="0 0 1024 1024" className="contact1-icon1">
          <path
            d="M928 128h-832c-52.8 0-96 43.2-96 96v640c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-640c0-52.8-43.2-96-96-96zM398.74 550.372l-270.74 210.892v-501.642l270.74 290.75zM176.38 256h671.24l-335.62 252-335.62-252zM409.288 561.698l102.712 110.302 102.71-110.302 210.554 270.302h-626.528l210.552-270.302zM625.26 550.372l270.74-290.75v501.642l-270.74-210.892z"
            className=""
          ></path>
        </svg>
      </div>
      <label className="contact1-label">{props.label}</label>
    </div>
  )
}

Contact1.defaultProps = {
  label: 'Contact@rec-it.net',
  rootClassName: '',
}

Contact1.propTypes = {
  label: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Contact1
