import React from 'react'

import PropTypes from 'prop-types'

import './card1.css'

const Card1 = (props) => {
  return (
    <section className={`card1-card ${props.rootClassName} `}>
      <div className="card1-icon">
        <svg viewBox="0 0 1024 1024" className="card1-icon01">
          <path
            d="M917.806 229.076c-22.208-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.884 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924v0 0zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.98 17.78 50.678 41.878 81.374 72.572v0 0zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.322 32 32 32h224v624z"
            className=""
          ></path>
          <path d="M256 64h128v64h-128v-64z" className=""></path>
          <path d="M384 128h128v64h-128v-64z" className=""></path>
          <path d="M256 192h128v64h-128v-64z" className=""></path>
          <path d="M384 256h128v64h-128v-64z" className=""></path>
          <path d="M256 320h128v64h-128v-64z" className=""></path>
          <path d="M384 384h128v64h-128v-64z" className=""></path>
          <path d="M256 448h128v64h-128v-64z" className=""></path>
          <path d="M384 512h128v64h-128v-64z" className=""></path>
          <path
            d="M256 848c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-160c0-26.4-21.6-48-48-48h-80v-64h-128v272zM448 768v64h-128v-64h128z"
            className=""
          ></path>
        </svg>
      </div>
      <main className="card1-content">
        <h1 className="card1-header">{props.header}</h1>
        <p className="card1-description">{props.description}</p>
      </main>
    </section>
  )
}

Card1.defaultProps = {
  header: 'Certifications Professionnelles',
  description:
    'Plus de 10 certifications: Azure Administrator Associate, Microsoft Certified: DevOps Engineer Expert, AWS Certified Solutions Architect, Huawei HCNA Storage, Nutanix Certified Systems Engineer, IBM Certified Specialist, et Veeam Certified Engineer.',
  icon: '5a45084f-3674-4342-b242-ce4e77a656b1',
  iconAlt: 'image',
  rootClassName: '',
}

Card1.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Card1
