import React from 'react'

import PropTypes from 'prop-types'

import './review111.css'

const Review111 = (props) => {
  return (
    <section className={`review111-card ${props.rootClassName} `}>
      <div className="review111-stars">
        <svg viewBox="0 0 1024 1024" className="review111-icon">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review111-icon02">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review111-icon04">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review111-icon06">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review111-icon08">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
      </div>
      <main className="review111-content">
        <p className="review111-quote">{props.quote}</p>
        <div className="review111-author">
          <div className="review111-details">
            <h1 className="review111-author1">{props.author}</h1>
            <label className="review111-position">{props.position}</label>
          </div>
        </div>
      </main>
    </section>
  )
}

Review111.defaultProps = {
  author: 'Walid MSEKNI',
  quote:
    "Je tiens à partager notre expérience positive de collaboration avec Yasmine Boudhina au sein de Star Assurance. Nous avons eu l'opportunité de travailler étroitement avec Yasmine sur des aspects essentiels de notre infrastructure existante, et son impact a été significatif.Le rôle de Yasmine impliquait principalement la documentation exhaustive de notre environnement, avec une attention particulière portée aux conteneurs DevOps. En outre, elle a assuré un soutien continu à l'équipe IT en facilitant le transfert de compétences. Sa contribution a été cruciale dans la réalisation de notre feuille de route infrastructurelle.L'expertise technique de Yasmine s'est révélée être un atout majeur. Elle a démontré une compréhension approfondie des défis spécifiques que nous rencontrions et a élaboré des solutions pragmatiques qui ont considérablement renforcé notre équipe. Son engagement inébranlable dans la réussite du projet a été exemplaire.Par-dessus tout, la capacité de Yasmine à faciliter le transfert de compétences a été particulièrement appréciée. Elle a su communiquer efficacement les connaissances nécessaires, renforçant ainsi notre équipe pour une autonomie accrue.",
  rootClassName: '',
  avatarSrc:
    'https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3NzU5NDE3&ixlib=rb-4.0.3&w=200',
  avatarAlt: 'image',
  position:
    "Manager - DIVISION SYSTEMES INFORMATIQUES chez Société Tunisienne d'Assurances et de Réassurances",
}

Review111.propTypes = {
  author: PropTypes.string,
  quote: PropTypes.string,
  rootClassName: PropTypes.string,
  avatarSrc: PropTypes.string,
  avatarAlt: PropTypes.string,
  position: PropTypes.string,
}

export default Review111
