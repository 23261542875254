import React from 'react'

import PropTypes from 'prop-types'

import './card11.css'

const Card11 = (props) => {
  return (
    <section className={`card11-card ${props.rootClassName} `}>
      <div className="card11-icon">
        <svg viewBox="0 0 1024 1024" className="card11-icon1">
          <path
            d="M832 960l192-512h-832l-192 512zM128 384l-128 576v-832h288l128 128h416v128z"
            className=""
          ></path>
        </svg>
      </div>
      <main className="card11-content">
        <h1 className="card11-header">{props.header}</h1>
        <p className="card11-description">{props.description}</p>
      </main>
    </section>
  )
}

Card11.defaultProps = {
  icon: '5a45084f-3674-4342-b242-ce4e77a656b1',
  rootClassName: '',
  header: "Projets IT d'Expertise",
  iconAlt: 'image',
  description:
    "Plus de 20 projets IT réussis pour des grands comptes, couvrant l'intégration de datacenters, la migration vers le cloud Azure, la virtualisation, sauvegarde, DR et la modernisation des applications avec Docker et l'administration de systèmes M365.",
}

Card11.propTypes = {
  icon: PropTypes.string,
  rootClassName: PropTypes.string,
  header: PropTypes.string,
  iconAlt: PropTypes.string,
  description: PropTypes.string,
}

export default Card11
