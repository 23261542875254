import React from 'react'

import PropTypes from 'prop-types'

import './review1111.css'

const Review1111 = (props) => {
  return (
    <section className={`review1111-card ${props.rootClassName} `}>
      <div className="review1111-stars">
        <svg viewBox="0 0 1024 1024" className="review1111-icon">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review1111-icon02">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review1111-icon04">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review1111-icon06">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
        <svg viewBox="0 0 1024 1024" className="review1111-icon08">
          <path
            d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"
            className=""
          ></path>
        </svg>
      </div>
      <main className="review1111-content">
        <p className="review1111-quote">{props.quote}</p>
        <div className="review1111-author">
          <div className="review1111-details">
            <h1 className="review1111-author1">{props.author}</h1>
            <label className="review1111-position">{props.position}</label>
          </div>
        </div>
      </main>
    </section>
  )
}

Review1111.defaultProps = {
  avatarAlt: 'image',
  rootClassName: '',
  author: 'Nizar BENOTHMAN',
  position: 'IT Service Delivery Manager chez Adactim',
  avatarSrc:
    'https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3NzU5NDE3&ixlib=rb-4.0.3&w=200',
  quote:
    "En collaboration avec Yasmine Boudhina, nous avons travaillé sur plusieurs projets qui ont impliqué des études de sizing, la conception d'infrastructures cibles, ainsi que des consultations techniques pour de grands comptes à l'échelle internationale. Son implication a été particulièrement notable dans la réussite de projets complexes tels que les migrations vers M365 et le cloud Azure, le conseil en modernisation d'applications avec l'utilisation de services modernes et innovants, l'animation d'ateliers, et sa participation en tant que conférencière lors d'événements sur le cloud Azure. Son rôle a été déterminant dans le succès de multiples initiatives. Sa maîtrise des solutions de divers constructeurs, combinée à ses compétences de présentation et de conseil auprès des clients, ont été des facteurs clés dans la réalisation réussie de ces projets.",
}

Review1111.propTypes = {
  avatarAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  author: PropTypes.string,
  position: PropTypes.string,
  avatarSrc: PropTypes.string,
  quote: PropTypes.string,
}

export default Review1111
