import React from 'react'

import PropTypes from 'prop-types'

import './card.css'

const Card = (props) => {
  return (
    <section className={`card-card ${props.rootClassName} `}>
      <div className="card-icon">
        <img alt={props.iconAlt} src={props.icon} className="card-icon1" />
      </div>
      <main className="card-content">
        <h1 className="card-header">{props.header}</h1>
        <p className="card-description">{props.description}</p>
      </main>
    </section>
  )
}

Card.defaultProps = {
  description:
    'Collaboration avec des leaders technologiques comme Veeam, Hewlett Packard Enterprise, Lenovo, Dell EMC, Huawei, Nutanix, Microsoft Azure, VMware, Docker, GitLab, Ansible, et Kubernetes pour offrir des solutions de pointe et innovantes à nos clients.',
  rootClassName: '',
  icon: '/Icons/group%201316-200w.png',
  iconAlt: 'image',
  header: 'Partenaires Technologiques',
}

Card.propTypes = {
  description: PropTypes.string,
  rootClassName: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  header: PropTypes.string,
}

export default Card
